/* SlidePane.css */
.slide-pane {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    background-color: #ffffff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    transition: right 0.3s ease;
    z-index: 1040;
}
/* Overlay styling */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
    z-index: 1035; /* Ensure the overlay is below the slide pane */
}
.overlay.visible {
    opacity: 1;
    visibility: visible;
}
.slide-pane.open {
    right: 0;
}

.slide-pane-content {
    padding: 20px;
}
.slide-pane-content .table-scroll,
.slide-pane-content .div-scroll {
    max-height: 85vh;
    min-height: 85vh;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}
.slide-pane-content .table-scroll thead th { position: sticky; top: 0; }
/* CSS for the scrollable tbody */
.slide-pane-content .table-scroll::-webkit-scrollbar,
.slide-pane-content .div-scroll::-webkit-scrollbar {
    width: 6px; /* Adjust the width here */
    height: 6px; /* Adjust the height here */
}

.slide-pane-content .table-scroll::-webkit-scrollbar-thumb,
.slide-pane-content .div-scroll::-webkit-scrollbar-thumb  {
    background-color: #888;
    border-radius: 10px;
}

.slide-pane-content .table-scroll::-webkit-scrollbar-track,
.slide-pane-content .div-scroll::-webkit-scrollbar-track  {
    background-color: #f1f1f1;
}


.close-btn {
    font-size: 32px;
    color: #333;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 15px;
}
/* Media query for larger screens */

@media (min-width: 768px) {
    .slide-pane {
        width: 80%; /* Adjust this percentage as needed */
    }
}

@media (min-width: 992px) {
    .slide-pane {
        width: 40%; /* Adjust this percentage as needed */
    }
}


/* Default for smaller screens */
@media (max-width: 767px) {
    .slide-pane-content .table-scroll,
    .slide-pane-content .div-scroll {
        max-height: 70vh;
        min-height: 70vh;
    }
    .slide-pane {
        width: 100%;
    }
}