@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Sarabun', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #393939;
  /* background: 
  linear-gradient( rgba(0, 0, 0, 0.5), rgba(1, 6, 53, 0.9)),
  url('./assets/images/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;*/
} 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
ul {
  padding-left: 0;
}

.drag-handle {
  cursor: grab;  /* Indicate draggable handle */
  margin-right: 10px;
}

.slide-in-right-modal .modal-dialog {
  position: fixed;
  right: 0;
  top: 0;
  margin: 0;
  height: 100%;
  transition: transform 0.3s ease-in-out; /* Add transition */
}

.slide-in-right-modal .modal-dialog.modal-entering {
  transform: translateX(100%); /* Start off-screen */
}

.slide-in-right-modal .modal-dialog.modal-entered {
  transform: translateX(0); /* Slide in */
}

.slide-in-right-modal .modal-dialog.modal-exiting {
  transform: translateX(100%); /* Slide out */
}

@keyframes bounceIn {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@keyframes closeModal {
  0% {
    transform: scale(1) translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: scale(0.2) translate(200%, -200%);
    opacity: 0;
  }
}

.modal.hide .modal-content {
  animation: closeModal 0.3s forwards ease-out;
}

.text-warning2 {
  --bs-text-opacity: 1;
  color: #FF9800 !important;
}

.variations-options{
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  padding: 7px;
  margin-bottom:4px;
}

.btn-col-4 {
  width: 33%;
}
.btn-custom-green,
.btn-custom-green:focus-visible{
  color: #000000;
  background-color: #c4fbcb;
  border-color: #c4fbcb;
}
.btn-custom-green:hover {
  color: #000000;
  background-color: #b6eebd;
  border-color: #b6eebd;
}
.btn-custom-green:first-child:active, 
:not(.btn-check)+.btn-custom-green:active {
  color: #000000;
  background-color: #b6eebd;
  border-color: #abe0b2;
}
